// Standard page specific styles

//Popup

#standard:has(#popup:not([style*='display: none'])) ~ .page-content {
  overflow: hidden;
}

.popup {
  position: fixed;
  z-index: 3;
  top: 5%;
  bottom: 20%;
  width: 60%;
  max-height: 34em;
  max-width: 50em;

  @include media-breakpoint-up(md) {
    // top: 25%;
  }

  color: white;
  display: flex;
  // background: lighten($primary, 5%);
  background-color: $primary;
  box-shadow: 2px -1px 6px 4px rgba(0, 0, 0, .4);

  .w-auto {
    min-width: 300px;
  }

  .h-auto {
    min-height: 400px;
  }

  .button {
    margin: 1.25rem 2rem;
    text-align: center;
    padding: .5rem 1.5rem;
    background-color: #FFFFFF;
    border: none;
    color: $primary;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2em;
    border-radius: 3px;
    align-self: center;
    width: 80%;
    max-width: 330px;
  }

  #popup-title {
    padding: 0.5em;
  }

  #popup-button {
    margin-top: 2.5em;
  }

  #mdiv {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
  }

  #mdiv:hover {
    cursor: pointer;
  }

  p {
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  h2 {
    color: white;
  }

  .mdiv {
    height: 15px;
    width: 2px;
    margin-left: 12px;
    margin-top: 6px;
    background-color: $secondary;
    transform: rotate(45deg);
    Z-index: 1;
  }

  .md {
    height: 15px;
    width: 2px;
    background-color: $secondary;
    transform: rotate(90deg);
    Z-index: 2;
  }
}

@media screen and (max-width: 800px) {
  .popup {
    width: 20em;
    max-height: 27em;
  }  
}


@media screen and (max-width: 992px) {

}

/*** GENERAL STREAMS ***/

.block-one_column {
  background-color: $text-on-color;
}

.block-portrait {
  background-color: $text-on-color;
}

#standard-container {
  background-color: $text-on-color;
}

// #page-title {
//   background-color: $text-on-color;
// }

.search-wrapper {
  background-color: $text-on-color;
  padding-bottom: 150px;
}


// Banner


.standard-banner {
  display: flex;
  flex-direction: column;
  background-color: $primary;
}

.banner-image {
  margin: auto;
  width: 1152px;
}

.banner-content {
  margin: auto;
  width: 1152px;
}

@media screen and (max-width: 1200px) {
  .banner-image, .main-banner-image {
    width: 959px;
  }

  // .main-banner-image {
  //   width: 956px;
  // }

  .banner-content {
    width: 959px;
  }
}

@media screen and (max-width: 991px) {
  .banner-image, .main-banner-image {
    width: 720px;
  }

  .banner-content {
    width: 720px;
  }
}

@media screen and (max-width: 768px) {
  .banner-image, .main-banner-image {
    width: 540px;
  }

  .banner-content {
    width: 540px;
  }
}

.banner-content p {
  color: $text-on-color;
  padding-left: 2em;
}

.banner-description {
  margin: 0;
  color: $text-on-color;
}

.standard-banner iframe {
  width: 80vw;
  height: 600px;
  margin: auto;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modal-content img {
  margin-left: 0.2px;
}

.mySlides p {
  // padding: 10px 10px 0px 10px;
}

// Motto 

.block-motto {
  display: flex;

  .motto {
    width: 100%;
  }
}

// Buttons

.button button {
  background-color: $button-color;
  border-radius: 5px;
  width: 285px;
  font-size: 1.4em;
  color: $text-on-color;
}

.block-button {
  display: flex;
  flex-direction: column;

  h2 {
    width: 200px;
  }

  button {
    width: 200px;
  }
}

// Two columns 

.two-columns {
  display: flex;
  // padding-top: 40px;

  .right {
    width: 50%;
  }

  .left {
    width: 50%;
    padding-right: 20px;
  }

  .left h2 {
    font-size: 2.5em;
    padding: 2em 0 0em;
  }

  .left h3 {
    font-size: 1.4em;
    padding-bottom: 1em;
    color: $secondary;
  }

  .left p {
    font-size: 1.3em;
  }

  img {
    overflow: hidden;
    object-fit: cover;
    width: 576px;
    // margin: auto;

  }

  @media screen and (max-width: 1200px) {
    img {
      width: 480px;
    }
  }

  @media screen and (max-width: 991px) {
    img {
      width: 360px;
    }
  }

  @media screen and (max-width: 768px) {
    img {
      width: 540px;
    }
  }



  a {
    -webkit-text-decoration: dotted underline;
    text-decoration: dotted underline;
    color: $primary-text;
  }

  a:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    color: $primary-text;
  }

  .two-col-btn {
    margin: 2em 0 1em;
  }
}

// One column

.one-column {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  flex-grow: 0;

  .richtext-image.full-width {
    width: 100%;
    height: auto;
  }

  a {
    -webkit-text-decoration: dotted underline;
    text-decoration: dotted underline;
    color: $primary-text;
  }

  a:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    color: $primary-text;
  }
}

// Subscribe 

.block-subscribe {
  .icon {
    display: flex;
    width: 100px;
    height: 100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .icon-text {
    margin-top: -50px;
    padding: 50px 0;
    color: white;

    h2 {
      color: white;
    }

    .right,
    .left {
      width: 60%;
    }

    .right {
      padding-left: 50px;
    }
  }
}

//Upcoming Events

.block-upcoming_events,
.block-events {
  .icon {
    display: flex;
    width: 100px;
    height: 100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  p {
    font-size: 1.2em;
  }

  .icon-text {
    margin-top: -50px;
    padding: 50px 0;
    color: white;
  }

  button {
    width: 100%;
    padding: 5px 50px;
  }

  hr {
    width: 100%;
    border-top: 1px dashed black;
    color: #fff;
    background-color: #fff;
    height: 1px;
  }
}

#events {
  h1 {
    padding-left: 0px;
  }

  display: flex;
  flex-direction: column;
  width: 100%;
}

#event-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#event-info {
  flex-direction: column;
}

//Carousel
.block-Carousel {
  width: 100%;
  margin-top: 30px;
  background-color: #f3f2f3;
  padding: 40px 0px;
  margin-bottom: 50px;
}

.carousel-title {
  color: $primary;
  text-align: center;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 15px;
}

ol.flickity-page-dots {
  display: none;
}

.carousel-cell {
  background-color: #f3f2f3;
  height: 400px;
  width: 25%;
  padding: 20px;

  @media (max-width: 960px) {
    width: 80%;
  }
}

.carousel-card {
  border: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;

  .header {
    font-size: 4.5rem;
    color: $primary;
  }

  h2 {
    color: $primary;
    font-size: 1.56rem;
    font-weight: 700;
    text-align: center;
  }

  p {
    padding-left: 30px;
    padding-right: 30px;
    color: #999999;
    font-size: 1rem;
    text-align: center;

    @media (max-width: 1300px) {
      font-size: .85rem;
    }
  }
}

//End Carousel

// Gallery 

.block-gallery {
  background-color: $primary;

  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  h2 {
    z-index: 3;
  }

  .icon {
    display: flex;
    width: 100px;
    height: 100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .icon-text {
    margin-top: -50px;
    padding: 25px 0;
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 33%;
    align-items: center;
    flex-grow: 1;
    padding-bottom: 20px;

    #gallery-title {
      color: $primary;
    }

    >div {
      flex-grow: 1;
      padding-bottom: 20px;

      >h2 {
        padding: 20px 30px;
      }
    }

    img {
      width: 100%;
      // height: 300px;
      object-fit: cover;
    }

    p,
    h2 {
      padding: 0px 30px;
    }

    a {
      -webkit-text-decoration: dotted underline;
      text-decoration: dotted underline;
      color: white !important;
    }

    a:hover {
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    button {
      background-color: $primary;
      padding: 10px 0;
      color: $text-on-color;
      width: 100%;
    }

    .button {
      width: 75%;
    }

  }

  .c1 {
    background-color: #FC7D41;
  }

  .c2 {
    background-color: #FDB476;
  }

  .c3 {
    background-color: #FDE0B6;
  }

  .c3 a,
  .c3 h1 {
    color: black;
  }

}

//Gallery Hover

.block-Gallery {
  padding: 40px 0px;
}

.gallery {
  width: 100%;
  display: flex;
  justify-content: row;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-item {
  position: relative;
  background-color: white;
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px;

  @media (max-width: 760px) {
    width: 100%;
  }
}

.gallery-caption {
  text-align: center;
  position: absolute;
  bottom: 0;
  background-color: $primary;
  opacity: 95%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    overflow: hidden;
    word-wrap: break-word;
    color: white;
    margin: 5px;
    margin-bottom: 5px;
  }
}

.gallery-button {
  font-size: 1.32rem;
}

.gallery-image {
  width: 100%;
  height: auto;
  max-height: 250px;
}

.hidden-content {
  padding: 30px;
  background-color: $secondary;
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  opacity: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1;

  p {
    text-align: center;
    line-height: 1.2;
  }
}

.background-medium-blue {
  background-color: #003E7E;

  h2 {
    color: white;
  }

  p {
    color: white;
  }

  a {
    color: white;
  }
}

.background-dark-blue {
  background-color: #00205B;

  h2 {
    color: white;
  }

  p {
    color: white;
  }

  a {
    color: white;
  }
}

.background-light-gray {
  background-color: #DEDEDE;

  h2 {
    color: #003E7E;
  }

  p {
    color: #003E7E;
  }

  a {
    color: #003E7E;
  }
}

.background-dark-gray {
  background-color: #53565A;

  h2 {
    color: white;
  }

  p {
    color: white;
  }

  a {
    color: white;
  }
}

.background-white {
  background-color: white;

  h2 {
    color: #003E7E;
  }

  p {
    color: #003E7E;
  }

  a {
    color: #003E7E;
  }
}

.background-yellow {
  background-color: #f4af0e;

  h2 {
    color: #003E7E;
  }

  p {
    color: #003E7E;
  }

  a {
    color: #003E7E;
  }
}

#background-medium-blue {
  background-color: #003E7E;

  h2 {
    color: white;
  }

  p {
    color: white;
  }

  a {
    color: #00205B;
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

#background-dark-blue {
  background-color: #00205B;

  h2 {
    color: white;
  }

  p {
    color: white;
  }

  a {
    color: #00205B;
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

#background-light-gray {
  background-color: #DEDEDE;

  h2 {
    color: #003E7E;
  }

  p {
    color: #003E7E;
  }

  a {
    color: white;
    background-color: #003E7E;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

#background-dark-gray {
  background-color: #53565A;

  h2 {
    color: white;
  }

  p {
    color: white;
  }

  a {
    color: #00205B;
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

#background-white {
  background-color: white;

  h2 {
    color: #003E7E;
  }

  p {
    color: #003E7E;
  }

  a {
    color: white;
    background-color: #003E7E;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

#background-yellow {
  background-color: #f4af0e;

  h2 {
    color: #003E7E;
  }

  p {
    color: #003E7E;
  }

  a {
    color: white;
    background-color: #003E7E;
    padding: 5px 10px;
    border-radius: 5px;
  }
}

.gallery-item:hover {
  .hidden-content {
    display: flex;
  }

  .gallery-caption {
    display: none;
  }
}

.modal-dialog {
  margin-top: 5%;

  img {
    height: 400px;
    width: 100%;
  }
}

//End Gallery Hover

//Call to action

.call-to-action {
  min-height: 400px;
  justify-content: space-between;

  >section {
    align-items: center;
    width: 33%;
    background-color: $primary;
  }

  .icon {
    color: $secondary;
    display: flex;
    width: 100px;
    height: 100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 2;
    flex-shrink: 0;
  }

  .text {
    color: $secondary;
    margin: -50px;
    flex-grow: 1;
    padding: 50px 35px 60px 35px;
    width: 87.5%;
  }

  .a-btn {
    display: flex;
    width: 87.5%;
    justify-content: center;
    padding: 10px 0 20px 0;

    button {
      width: 75%;
      padding: 10px 0;
      color: $text-on-color;
      border: none;
      font-size: 22px;
      font-family: Oswald, sans-serif;
      font-weight: 400;
      background-color: $secondary;
      border-radius: 200px;
    }
  }
}

.social-media {
  .icon {
    display: flex;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: $primary;
  }

  .facebook,
  .icon {
    margin: 8px;
  }
}

//Slideshow

.slide-content {
  width: 100%;
  height: 3em;
  // align-items: center;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
}

.slide-content .w-100 {
  align-self: center;
  width: 50% !important;
}

.slide-info {
  padding-top: 10px;
  padding-left: 2em;
  color: white;
  word-wrap: break-word;
  width: 100%;
}

.slide-info .button .w-100 {
  font-size: 32px;
  color: #003E7E;
  margin-left: 50%;
  height: 60px;
  border-radius: 10px;
}

.slide-info h1 {
  padding-top: 10px;
  color: white;
  align-items: center;
}

.slide-info p {
  color: white;
  align-items: center;
}

.slideshow {
  width: 100%;
}

.mySlides {
  display: none;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #17315e;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.mySlides iframe {
  width: 100%;
  height: 550px !important;
}

@media screen and (min-width: 1200px) {
  .main-banner-image {
    width: 1152px;
    height: 550px;
    object-fit: cover;
  }
}

.slide-prev {
  font-size: 15px;
  cursor: pointer !important;
  position: absolute;
  top: 35%;
  left: 20%;
}

@media screen and (max-width: 900px) {
  .slide-prev {
    left: 15%;
  }
}

.slide-next {
  font-size: 15px;
  cursor: pointer !important;
  position: absolute;
  top: 35%;
  right: 20%;
}

@media screen and (max-width: 900px) {
  .slide-next {
    right: 15%;
  }
}

.fades {
  -webkit-animation-name: fades;
  -webkit-animation-duration: 1.5s;
  animation-name: fades;
  animation-duration: 1.5s;
}

@-webkit-keyframes fades {
  from {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
}

@keyframes fades {
  from {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
}

// Portrait

.block-portrait {
  .d-flex {
    flex-wrap: wrap;
  }

  img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
  }

  p {
    margin-bottom: 0.1rem;
    padding: 10px 10px 0px 10px;
  }

  .position {
    padding: 0;
  }

  .name {
    font-size: 18px;
  }

  .portrait {
    display: flex;
    flex-direction: column;
    width: 33%;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    align-items: center;
  }
}

//Quick links

.block-quick_links {
  a {
    color: black;
    text-decoration: dotted underline;
    text-underline-position: under;
  }

  a:hover {
    text-decoration: none;
  }
}