$primary: #17315e;
$primary-text: black;
$secondary-text: #27345C;
$text-on-color: white;
$selected: #c7c7c7;
$non-selected: #FFFFFF33;
$secondary: #3A8B8E;
$alternate: #8AB4A4;
$button-color: #FA4E32;
$alert: #FC7D41;
$submenu-highlight: #FDE0B6;