@import "_variables.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "_standard.scss";
@import "_mobile.scss";
// fonts
@import url('https://fonts.cdnfonts.com/css/minion-pro');
@import url('https://fonts.cdnfonts.com/css/gill-sans-mt-2');

@font-face {
    font-family: 'Gill Sans';
    src: url('/static/fonts/Gill Sans Medium.otf') format('opentype');
}

// Sass sheet for changes to the style on all pages


// Tyopgraphy
h1 {
    font-size: 2.5em;
    font-family: 'Gill Sans MT', sans-serif !important;
    font-weight: 400;
    color: $primary;
    padding: 8px;
}

h2 {
    font-size: 2em;
    font-family: 'Gill Sans MT', sans-serif;
    font-weight: 400;
    padding-top: 16px;
    color: $secondary-text;
}

h2:first-child {
    padding-top: 0px;
}

h3 {
    font-size: 1.5em;
    color: black;
    font-family: 'Gill Sans MT', sans-serif;
    font-weight: 400;
    color: $secondary-text;
}

p {
    font-size: 1.2em;
    font-family: 'Minion Pro', sans-serif;
    font-weight: 400;
    color: $primary-text;
}

button {
    font-family: 'Gill Sans MT', sans-serif;
    padding: 6px 12px;
    border: none;
    font-size: 1.4em;
    color: $button-color;
}

.logotype {
    font-size: 40px;
}

.form-control {
    color: $text-on-color;
    background-color: $primary;
    border-color: $non-selected;
}

.form-control:focus {
    color: $text-on-color;
    border-color: $text-on-color;
    background-color: $primary;
} 

.btn-outline-light { 
    color: $non-selected;
    border-color: $non-selected;
    background-color: $primary;
}

.btn-outline-light:hover { 
    color: $text-on-color;
    border-color: $text-on-color;
    background-color: $primary;
}

.call-to-action h2,
.call-to-action h3,
.call-to-action p,
.gallery h2,
.gallery h3,
.gallery p {
    // color: $secondary !important;
}

// General
body {
    margin: 0;
    height: 100vh;
    background-color: $primary;
}

button:hover {
    -webkit-filter: brightness(80%);
    filter: brightness(80%);
}

p:empty {
    display: none;
}

.caption {
    font-size: 14px;
}

.global-colored {
    background-color: $primary;
}

.colored {
    background-color: $primary;
}


// Header

.header-container {
    display: flex;
    flex-direction: row;   
}

.main-menu-container {
    width: 100vw; 
}

.top-menu-container {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
}

.menu-items-container {
    display: flex;
}

.header-space {
    width: 100%;
    height: 10px;
}

.dropdown-menu .dropdown-submenu .dropdown-menu {
    top: 0;
    right: 0;
    min-width: none;
    width: 5em !important; 
}

#mobile-title {
    display: none;
}

#desktop-title {
    text-wrap: wrap;
    width: 12em;
    height: 2.7em;
    font-size: 1.5em;
    color: $text-on-color;
    display: block;
}

#logo {
    width: 3.75em;
}

.navbar {
    padding: 0;
    z-index: 3;
    background-color: $primary;

    #navbarSupportedContent {
        justify-content: flex-end;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
        padding: 0;
    }

    .nav-link {
        font-size: 1.2em;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, sans-serif;
        font-weight: 500;
        padding: 0.5em;
        margin: 0 0.5em;
        color: $text-on-color !important;
    }

    .nav-item,
    #header-donate {
        display: flex;
        align-items: center;
    }

    #header-donate:hover {
        text-decoration: none;
    }

    .nav-link:hover {
        background-color: $primary;
        color: white !important;
    }

    .nav-item:hover {
        background-color: $primary;
    }

    .nav-item:hover .nav-link {
        color: white !important;
    }

    .container,
    .navbar-collapse {
        align-items: stretch;
    }

    .main-menu-nav {
        background-color: $primary;
        width: 1152px;
        margin: 0 auto;
    }

    .action-nav {
        background-color: $secondary;
        width: 1152px;
        margin: 0 auto;
    }

    .action-container {
        margin: 0 2em 0 auto;
    }

    .dropdown-menu {
        width: 1em;
        font-size: 1.1em;
        margin-right: auto;
        padding: 0;
        top: 60%;
        margin-top: 0.8em;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, sans-serif;
        text-transform: none;
    }

    // .dropdown-menu-show li a {
    //     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif, sans-serif !important;
    // }

    .dropdown-item {
        white-space: normal;
        color: $primary;
        padding: 0.7em;
    }

    .dropdown-item:hover {
        background-color: $submenu-highlight;
    }

    .nav-link {
        // margin-right: auto;
        width: 100%;
    }

    // Search bar
    .search-container {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    #header-search {
        margin-left: 0;
    }

    .form-inline {
        flex-flow: row nowrap;
    }

    @media screen and (max-width: 1200px) {
        .main-menu-nav {
            width: 959px;
        }
    
        .action-nav {
            width: 959px;
        }
        
        .search-container {
            // width: 959px;
        }
    }

    @media screen and (min-width: 992px) {
        #mobile-title {
            display: none;
            
        }
        .nav-container {
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            background-color: $primary;
        }
    }
    @media screen and (max-width: 992px) {
        .header-container {
            flex-direction: column;
        }

        .navbar-toggler {
            background-color: $text-on-color;
        }

        // .navbar-toggler:hover {
        //     background-color: $text-on-color;
        // }

        .button-header {
            display: flex;
            flex-direction: row;
            width: 720px;
            margin: 0 auto;
            background-color: $primary;
            
        }

        .desktop-title {
            display: none;
        }

        #mobile-title {
            display: block;
            margin: 0 7.5em 0 auto;
            font-size: 1em;
            color: $text-on-color;
        }

        .main-menu-nav {
            width: 720px;
            padding: 0;
            // flex-direction: column;
        }

        .action-nav {
            width: 100%;
            // flex-direction: column;
        }

        .main-menu-container {
            width: 100%;
            // font-size: 0.5em;
        }

        .menu-items-container {
            flex-direction: column;
        }

        .action-container {
            width: 720px;
            margin: 0 auto;
        }

        .nav-item {
            flex-direction: column;
        }

        .dropdown-menu {
            width: 100%;
        }

        .search-container {
            width: 720px;
            align-items: flex-start;
            background-color: $primary;
        }
    }

    @media screen and (max-width: 768px) {
        .main-menu-nav {
            width: 540px;
        }

        .action-nav {
            width: 100%;
        }

        .action-container {
            width: 540px;
        }

        .button-header {
            width: 540px;
        }

        .search-container {
            width: 540px;
        }

        #search-form {
            display: flex;
        }

    }

    @media screen and (max-width: 576px) {
        #mobile-title {
            margin: 0;
            font-size: 0.7em;
        }

        .main-menu-nav {
            width: 100vw;
        }

        .action-nav {
            width: 100vw;
        }

        .action-container {
            width: 100%;
        }

        .button-header {
            width: 100vw;
        }

        .main-menu-container {
            // width: 100%;
            // font-size: 0.45em;
        }

        .action-container {
            // width: 100%;
            // font-size: 0.45em;
        }

        #desktop-title {
            width: 13em;
        }

        .search-container {
            width: 100vw;
        }
    }
}

.navbar-toggler-icon .sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

#search-form {
    display: flex;
    position: relative;
    // display: none;
    // width: 100%;

    &.show {
        display: block;
    }

    padding: 0.5em;
    background: $primary;
}

.breadcrumb {
    .container {
        display: flex;
        flex-direction: row;
    }
}

@media screen and (max-width: 992px) {
    #search-form {
        width: 100%;
    }
}

#header-search {
    margin-left: 70%;

    @media screen and (max-width: 900px) {
        margin-left: 0;
        width: 50%;
    }
}

// FORM

#contact-page-title {
    color: $text-on-color;
}

#thank-you-container {
    font-size: 1.4em;
    padding: 0.5em;

    p {
        color: $text-on-color;
    }
}

.form-container {
    flex: 100%;
    max-width: none;

    .form-group {
        display: flex;
        flex-direction: column;

        #id_test, #id_first_name, #id_last_name, #id_email_address, #id_cell_phone_no, #id_height, #id_age {
            width: 50%;
        }

        #id_questions_or_comments {
            width: 75%;
        }
    }
}

// @media (min-width: 767px) {
//     .form-container {
//         width: 50%;
//     }
// }


@media (min-width: 1300px) {
    .navbar-expand-custom {
        flex-direction: row;
        justify-content: flex-start;

        >.container {
            flex-wrap: nowrap;
        }

        .navbar-nav .dropdown-menu {
            position: relative;
            min-width: none;
            width: 5em !important;

        }
    }

    .navbar-expand-custom .navbar-nav {
        flex-direction: row;
    }

    .navbar-expand-custom .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }

    .navbar-expand-custom .navbar-collapse {
        display: flex !important;
    }

    .navbar-expand-custom .navbar-toggler {
        display: none;
    }
}

#multi-site {
    h1 {
        font-size: 32px;
        padding-left: 0px;
    }
}

#twitter-widget-0 {
    iframe {
        height: auto !important;
    }
}

.footer {
    background-color: $primary;
    padding-top: 30px;
    // margin-top: 30px;

    h6 {
        color: rgb(125, 125, 125);
    }

    p {
        padding: 0;
        margin: 0;
        color: $text-on-color;
    }

    .footer-container {
        display: flex;
        flex-direction: column;
    }

    .footer-info {
        display: flex;
        flex-direction: row;
    }

    .footer-element {
        margin: 0 1em 0 1em;
    }

    .box-office {
        margin: 0;
    }

    .donate-link {
        margin-left: auto;
    }

    .address {
        margin-top: 2em;
    }

    .footer-text {
        padding-top: 20px;
        padding-bottom: 50px;

        p {
            font-size: 1em;
        }
    }

    .social-icons {
        width: 2.5em;
        margin: 0 0.7em 2em 0;
    }

    @media screen and (max-width: 992px) {
        .footer-info {
            display: flex;
            flex-direction: column;
        }

        .footer-element {
            margin: 0;
            margin-top: 1em;
        }

        .donate-link {
            margin-left: 0;
        }

        .donate-btn {
            margin-top: 1em;
            width: 120px;
            font-size: 1.2em;
        }
    }

}