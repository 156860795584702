/* Mobile */

@media screen and (max-width: 992px) {

    .button-header {
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        background-color: $primary;

        .navbar-toggler {
            display: block;
        }

        #desktop-title {
            display: none;
        }

        #mobile-title {
            display: flex !important;
            font-size: 1em;
        }
    }

    .nav-container {
        .navbar-nav {
            flex-direction: column;
            // padding: 1em !important;

            .action-container {
                flex-direction: column !important;
            }

            .container {
                flex-direction: column;
                margin: 0;
                padding: 0;

                .nav-item {
                    padding: 0 15px;
                }

                h1 {
                    padding: 0 15px;
                }
            }
        }
    }

    #multi-site {
        h1 {
            white-space: normal;
            padding-left: 16px !important;
        }

        .nav-item {
            padding-left: 16px !important;
        }
    }

    // .navbar-toggler {
    //     margin-left: 15px;
    //     margin-bottom: 10px;
    // }

    .two-columns {
        flex-direction: column;
        padding: 0 1em;

        .right,
        .left {
            width: 100%;
            padding: 0;
        }
    }

    @media screen and (max-width: 768px) {
        #popup-button {
            margin-top: 0em;
        }

        // .banner-image, .main-banner-image {
        //     width: 540px;
        // }

        // .banner-content {
        //     width: 540px;
        // }

        // .two-columns img {
        //     width: 540px;
        // }
    }

    @media screen and (max-width: 640px) {
        body {
            padding: 0 !important;
        }
    }

    @media screen and (max-width: 576px) {

        .two-columns h2 {
            padding: 0em 1em;
        }

        .two-columns h3 {
            padding: 0 1em;
        }

        .two-columns p {
            padding: 0 1em;
        }

        .banner-image, .main-banner-image {
            width: 100%;
        }

        .banner-content {
            width: 100%;
        }

        .two-columns img {
            width: 100%;
        }
    }

    .one-column {
        padding: 0 1em;

        img {
            width: 100%;
            position: relative;
            // margin-left: -50vw;
            // left: 50%;
        }
    }

    .call-to-action {
        flex-direction: column;

        >section {
            width: 100%;
            padding-bottom: 20px;
        }
    }

    .block-subscribe .container {
        flex-direction: column;

        .left,
        .right {
            width: 100%;
        }
    }

    .block-gallery {
        h2 {
            padding: 15px;
        }

        .container {
            width: 100%;
            padding: 0;

            >section {
                flex-direction: column;

                .column {
                    width: 100%;
                }
            }
        }
    }

    .block-upcoming_events {
        .container {
            margin-left: 0% !important;
        }
    }

    .footer {
        .container {
            padding: 40px;
        }
    }

    .block-portrait .d-flex {
        flex-direction: column;

        .portrait {
            width: 100%;
        }
    }

    .standard-banner {
        flex-direction: column;
    }

    .standard-banner .banner-content {
        // width: 100%;
    }
}